export const environment = {
  version: require("../../package.json").version,
  production: false,
  staging: true,
  development: false,
  apiUrl: "https://apimobiletest2.soliportal.com/api/v1",
  notificationsUrl: "http://localhost:5000/notificationhub",
  cognitoClientId: "4u16u9tnk21nddvmn6fqdivl0l",
  cognitoRegion: "us-east-2",
  cognitoUserPoolId: "us-east-2_3zMrpB4po",
  program: [
    {
      state: 'NM',
      stateName: 'New Mexico',
      programCode: 'NM'
    },
    {
      state: 'NM',
      stateName: 'New Mexico',
      programCode: 'FS'
    },
    {
      state: 'LA',
      stateName: 'Louisiana',
      programCode: 'LA'
    },
    {
      state: 'CA',
      stateName: 'Test abc',
      programCode: 'CA'
    },
    {
      state: 'AK',
      stateName: 'Zeta',
      programCode: 'AK'
    }, 
    {
      state: 'CT',
      stateName: 'Connecticut',
      programCode: 'CT'
    },
    {
      state: 'AL',
      stateName: 'Alabama',
      programCode: 'AL'
    },
    {
      state: 'OK',
      stateName: 'Oklahoma',
      programCode: 'CN'
    }
  ]
};
