import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import * as lpn from "google-libphonenumber";
import moment from "moment";
import { TypeaheadMatch } from "ngx-bootstrap/typeahead";
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from "ngx-intl-tel-input";
import { Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { NotificationsService } from "src/app/core/notifications/notifications.service";
import { GenericFunctionsService } from "src/app/core/services/generic-functions.service";
import { LookupService } from "src/app/core/services/lookup.service";
import { ParticipantSignUpService } from "src/app/core/services/participant-sign-up.service";
import { AdditionalProgramEligibilityDTO } from "src/app/dtos/additionalProgramEligibilityDTO";
import { LookupValueDTO } from "src/app/dtos/lookupValueDTO";
import { SignUpCommand } from "src/app/dtos/signUpCommand";
import { SignUpResponse } from "src/app/dtos/signUpResponse";
import { SignUpDataService } from "../sign-up-data.service";

export function olderThan(years = 10): ValidatorFn {
  return function validate(control: AbstractControl) {
    const age = moment().diff(moment(control.value), "years");
    const isMajor = age >= years;
    if (!isMajor) {
      return {
        major: {
          major: years,
        },
      };
    }
    return null;
  };
}
@Component({
  selector: "app-sign-up-updates",
  templateUrl: "./sign-up-updates.component.html",
  styleUrls: ["./sign-up-updates.component.scss"],
})
export class SignUpUpdatesComponent implements OnInit {
  signUpForm: FormGroup;
  errors: { [key: string]: string[] };
  minDate = moment().subtract(122, "years").toDate();
  maxDate = new Date();

  dataSourceState: Observable<LookupValueDTO[]>;
  typeaheadStateLoading = false;
  typeaheadStateNoResults = false;
  selectedState;

  dataSourceCounty: Observable<LookupValueDTO[]>;
  typeaheadCountyLoading = false;
  typeaheadCountyNoResults = false;

  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates];
  phoneUtil: any = lpn.PhoneNumberUtil.getInstance();

  @Output() formSubmit = new EventEmitter<any>();
  @Output() formCancel = new EventEmitter<void>();
  @Output() formEdit = new EventEmitter<void>();


  imgPath: string;
  ethnicItems: LookupValueDTO[];
  anio: number = new Date().getFullYear();
  cardSelection = false;
  stateList = [
    {
      id: "CT",
      description: "CT-Connecticut",
      name: "Connecticut",
    },
    {
      id: "NM",
      description: "New Mexico",
      name: "New Mexico",
    },
  ];
  additionalProgramEligibilityList = [
    {
      id: "CSFP",
      name: "CSFP (Commodity Supplemental Food Program)",
    },
    {
      id: "Medicaid",
      name: "Medicaid",
    },
    {
      id: "SNAP",
      name: "SNAP (Supplemental Nutrition Assistance Program)",
    },
    {
      id: "SSI",
      name: "SSI (Supplemental Security Income)",
    },
    {
      id: "TANF",
      name: "TANF (Temporary Assistance for Needy Families)",
    },
    {
      id: "TEFAP",
      name: "TEFAP (Temporary Emergency Food Assistance Program)",
    },
  ];
  userData: any;
  // siteKey = environment.recaptcha.siteKey;
  raceItems: LookupValueDTO[];
  dataSourceTribal: Observable<LookupValueDTO[]>;
  typeaheadTribalLoading = false;
  typeaheadTribalNoResults = false;

  additionalProgramEligibilityItems: AdditionalProgramEligibilityDTO[];
  stateObj = {
    state: '',
    stateName: '',
    programCode: '',
  }

  constructor(
    private fb: FormBuilder,
    private lookupService: LookupService,
    public genericFunctions: GenericFunctionsService,
    private participantSignUpService: ParticipantSignUpService,
    private router: Router,
    private modalService: NgbModal,
    private notifications: NotificationsService,
    public translate: TranslateService,
    private signUpDataService: SignUpDataService
  ) {
    translate.addLangs(["en", "es"]);
    translate.setDefaultLang("en");
    this.signUpDataService.currentUser.subscribe((data) => {
      this.userData = data;
    });
    this.onLoadData();
  }

  ngOnInit(): void {
    //this.translate.store.currentLang = "en";
    this.signUpDataService.currentLanguage.subscribe((lang) => {
      if (lang && (lang === "en" || lang === "es")) {
        this.translate.use(lang);
      } else {
        this.translate.use("en"); // Default to English if language is undefined or invalid
      }
    });
    // this.signUpDataService.currentUser.subscribe((data) => {
    //   this.userData = data;
    //   if (this.userData && this.userData.translate) {
    //     this.translate.use(this.translate?.store?.currentLang);
    //   }
    // });
    if(this.translate) {
      this.translate.use(this.translate?.store?.currentLang);
    }
    
    // this.additionalProgramEligibilityFormArray.clear();
    for (let i = 0; i < 6; i++) {
      const value: boolean = this.userData.additionalProgramEligibility[i]
        ? true
        : false;
      this.additionalProgramEligibilityFormArray.push(new FormControl(value));
    }
    this.signUpForm.disable();
  }

  onLoadData() {
    // this.translate.currentLang === "en"
    //   ? this.translate.use("en")
    //   : this.translate.use("es");
    this.signUpForm = this.fb.group({
      translate: this.translate?.store?.currentLang === "en" ? "en" : "es",
      certify: ["", Validators.compose([Validators.required])],
      certifyDual: ["", Validators.compose([Validators.required])],

      firstName: [
        "",
        Validators.compose([Validators.required, Validators.maxLength(100)]),
      ],
      middleName: ["", Validators.compose([Validators.maxLength(100)])],
      lastName: [
        "",
        Validators.compose([Validators.required, Validators.maxLength(100)]),
      ],
      dateOfBirth: ["", Validators.compose([Validators.required])],
      gender: ["", Validators.compose([Validators.required])],
      state: [""],
      stateName: [""],
      city: [
        "",
        Validators.compose([Validators.required, Validators.maxLength(50)]),
      ],
      zipCode: [
        "",
        Validators.compose([
          Validators.required,
          Validators.maxLength(10),
          Validators.pattern("^[0-9]{5}(?:-[0-9]{4})?$"),
        ]),
      ],
      addressLine: [
        "",
        Validators.compose([Validators.required, Validators.maxLength(100)]),
      ],
      addressLine2: ["", Validators.compose([Validators.maxLength(100)])],

      county: [
        "",
        Validators.compose([Validators.required, Validators.maxLength(100)]),
      ],
      countyName: [
        "",
        Validators.compose([Validators.required, Validators.maxLength(100)]),
      ],
      email: [
        "",
        Validators.compose([
          Validators.pattern(
            "[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}"
          ),
          Validators.maxLength(100),
        ]),
      ],
      phoneNumber: ["", Validators.required],
      ethnics: ["", Validators.required],
      numberInHousehold: ["0", Validators.compose([Validators.required])],
      grossMonthlyIncome: ["", Validators.compose([Validators.required])],
      newCard: ["false"],
      cardNumber: [""],
      proxyName: ["", Validators.compose([Validators.maxLength(100)])],
      language: ["", Validators.compose([Validators.required])],
      race: ["", Validators.compose([Validators.required])],
      tribal: [""],
      tribalName: [""],
      additionalProgramEligibility: this.fb.array([]),
      submit: [false],
      externalUserId: ["", Validators.compose([Validators.required, Validators.maxLength(255)])],

      // recaptcha: ["", Validators.required],
    });
    // this.dataSourceState = new Observable((observer: any) => {
    //   this.signUpForm.patchValue({ state: null });
    //   observer.next(this.signUpFormControls.stateName.value);
    // }).pipe(
    //   mergeMap((token: string) => this.lookupService.searchStates(token))
    // );
    this.dataSourceCounty = new Observable((observer: any) => {
      this.signUpForm.patchValue({ county: null });
      observer.next(this.signUpFormControls.countyName.value);
    }).pipe(
      mergeMap((token: string) => this.lookupService.searchCounties(token, this.signUpFormControls.state.value))
    );
    this.dataSourceTribal = new Observable((observer: any) => {
      this.signUpForm.patchValue({ tribal: null });
      observer.next(this.signUpFormControls.tribalName.value);
    }).pipe(
      mergeMap((token: string) => this.lookupService.searchTribals(token))
    );

    this.lookupService.searchAllRaces().subscribe((response) => {
      this.raceItems = response;
    });
    if (this.userData) {
      this.signUpForm.patchValue({ translate: this.translate?.store?.currentLang });
      this.switchLang(this.translate?.store?.currentLang);
      this.translate.use(this.translate?.store?.currentLang);
      this.signUpForm.patchValue({
        certify: this.userData.certify,
        certifyDual: this.userData.certifyDual,
        firstName: this.userData.firstName,
        middleName: this.userData.middleName,
        lastName: this.userData.lastName,
        dateOfBirth: this.userData.dateOfBirth,
        gender: this.userData.gender,
        language: this.userData.language,
        race: this.userData.race,
        tribal: this.userData.tribal,
        tribalName: this.userData.tribalName,
        stateName: this.userData.stateName,
        state: this.userData.state,
        city: this.userData.city,
        zipCode: this.userData.zipCode,
        addressLine: this.userData.addressLine,
        addressLine2: this.userData.addressLine2,
        county: this.userData.county,
        countyName: this.userData.countyName,
        email: this.userData.email,
        phoneNumber: this.userData.phoneNumber,
        ethnics: this.userData.ethnics,
        proxyName: this.userData.proxyName,
        numberInHousehold: this.userData.numberInHousehold,
        grossMonthlyIncome: this.userData.grossMonthlyIncome,
        additionalProgramEligibility:
          this.userData.additionalProgramEligibility,
        newCard: this.userData.newCard,
        cardNumber: this.userData.cardNumber,
        externalUserId: this.userData.externalUserId,
      });
      const pathName = globalThis.location.pathname.substring(globalThis.location.pathname.lastIndexOf('/') + 1);
      this.stateObj.state = this.userData.state;
      this.stateObj.stateName = this.userData.stateName;
      this.stateObj.programCode = (this.userData.programCode.toLowerCase() !== pathName.toLowerCase()) ? 'LA' : this.userData.programCode;
      this.imgPath = `assets/img/${this.stateObj.programCode.toLowerCase()}/${this.stateObj.programCode.toLowerCase()}.png`;
      this.cardSelection = this.userData.newCard == 'true'? true: false;
    }
    this.additionalProgramEligibilityItems =
      this.additionalProgramEligibilityList.map((x) => {
        return {
          id: x.id,
          name: x.name,
        };
      });
  }
  

  onCheckboxClicked() {
    const certifyCheck = document.querySelector(".certify-check");
    const certifyCheck1 = document.querySelector(".certify-check1");
    if (this.signUpFormControls.certify.value == true) {
      certifyCheck.classList.add("checked");
    } else if (this.signUpFormControls.certify.value == false) {
      certifyCheck.classList.remove("checked");
    }
    if (this.signUpFormControls.certifyDual.value == true) {
      certifyCheck1.classList.add("checked");
    } else if (this.signUpFormControls.certifyDual.value == false) {
      certifyCheck1.classList.remove("checked");
    }
  }

  submit() {
    const additionalSelectedPrograms =
      this.signUpFormControls.additionalProgramEligibility.value
        .map((checked, i) =>
          checked ? this.additionalProgramEligibilityList[i].id : null
        )
        .filter((id) => id !== null);

    const signUpDto: SignUpCommand = {
      firstName: this.signUpFormControls.firstName.value,
      middleName: this.signUpFormControls.middleName.value,
      lastName: this.signUpFormControls.lastName.value,
      dateOfBirth: this.signUpFormControls.dateOfBirth.value,
      gender: this.signUpFormControls.gender.value,

      state: this.signUpFormControls.state.value,
      city: this.signUpFormControls.city.value,
      zipCode: this.signUpFormControls.zipCode.value,
      addressLine: this.signUpFormControls.addressLine.value,
      addressLine2: this.signUpFormControls.addressLine2.value,
      county: this.signUpFormControls.county.value,

      email: this.signUpFormControls.email.value,
      phoneNumber: this.signUpFormControls.phoneNumber.value
        ? this.signUpFormControls.phoneNumber.value
        : "",

      ethnics: this.signUpFormControls.ethnics.value,
      numberInHousehold:
        this.signUpFormControls.numberInHousehold.value != null
          ? Number(this.signUpFormControls.numberInHousehold.value)
          : 0,
      grossMonthlyIncome:
        this.signUpFormControls.grossMonthlyIncome.value != null
          ? Number(this.signUpFormControls.grossMonthlyIncome.value)
          : null,

      newCard: this.signUpFormControls.newCard.value == "true" ? true : false,
      cardNumber: this.signUpFormControls.cardNumber.value != null ? this.signUpFormControls.cardNumber.value.replace(/\s/g, "") : null,
      proxyName: this.signUpFormControls.proxyName.value,
      language: this.signUpFormControls.language.value,
      race: this.signUpFormControls.race.value,
      tribal: this.signUpFormControls.tribal.value,
      additionalProgramEligibility: additionalSelectedPrograms,
      programCode: this.userData.programCode,
      externalUserId: this.userData.externalUserId
    };

    this.participantSignUpService.signUp(signUpDto).subscribe(
      (response: SignUpResponse) => {
        this.notifications
          .confirm(
            this.translate.store.currentLang == "en"
              ? "Sign Up Complete!"
              : "Registrarse completa!",
            this.translate.store.currentLang == "en"
              ? "Thank you for applying for the Senior Farmers' Market Nutrition Program. A program administrator will review your application and determine your eligibility. \n\n" +
                  "Note: If another household member needs to submit an application, please click the red “New Application” button. Otherwise, select “Close”."
              : "Gracias por solicitar el Programa de Nutrición del Mercado de Agricultores para Personas Mayores. Un administrador del programa revisará su solicitud y determinará su elegibilidad. \n\n" +
                  "Nota: Si otro miembro del hogar necesita presentar una solicitud, haga clic en el botón rojo “Nueva Solicitud”. De lo contrario, seleccione “Cerrar”.",
            this.translate.store.currentLang == "en" ? "Close" : "Cerrar",
            this.translate.store.currentLang == "en"
              ? "New Application"
              : "Nueva Solicitud"
          )
          .then(
            () => {
              this.onLoadData();
              this.signUpForm.patchValue({
                race: "",
              });
            },
            () => {
              this.signUpForm.reset();
              this.signUpForm.patchValue({
                race: "",
              });
              this.router.navigate([`/auth/sign-up/${this.stateObj.programCode.toLowerCase()}`]);
            }
          );
      },
      (error) => {
        this.errors = error?.errors;
      }
    );
    this.formSubmit.emit(this.signUpForm.value);
  }

  close() {
    this.formCancel.emit();
  }

  public get signUpFormControls(): any {
    return this.signUpForm.controls;
  }

  public get additionalProgramEligibilityFormArray(): FormArray {
    return this.signUpForm.get("additionalProgramEligibility") as FormArray;
  }

  public changeTypeaheadStateLoading(e: boolean): void {
    this.typeaheadStateLoading = e;
  }

  public changeTypeaheadStateNoResults(e: boolean): void {
    this.typeaheadStateNoResults = e;
  }

  public typeaheadStateOnSelect(e: TypeaheadMatch): void {
    this.signUpForm.patchValue({
      state: e.item.id,
      stateName: e.item.name,
    });
  }

  public changeTypeaheadCountyLoading(e: boolean): void {
    this.typeaheadCountyLoading = e;
  }

  public changeTypeaheadCountyNoResults(e: boolean): void {
    this.typeaheadCountyNoResults = e;
  }

  public typeaheadCountyOnSelect(e: TypeaheadMatch): void {
    this.signUpForm.patchValue({
      county: e.item.id,
      countyName: e.item.name,
    });
  }
  public changeTypeaheadTribalLoading(e: boolean): void {
    this.typeaheadTribalLoading = e;
  }

  public changeTypeaheadTribalNoResults(e: boolean): void {
    this.typeaheadTribalNoResults = e;
  }

  public typeaheadTribalOnSelect(e: TypeaheadMatch): void {
    this.signUpForm.patchValue({
      tribal: e.item.id,
      tribalName: e.item.name,
    });
  }
  displayTribal(filter: any) {
    let vals: string[] = filter;
    if (vals.length > 0) {
      let count = 0;
      for (var item in vals) {
        if (vals[item] === "R-AIA") {
          count++;
        }
      }
      if (count == 1) {
        this.signUpFormControls.tribal.clearValidators();
        this.signUpFormControls.tribalName.clearValidators();
        this.signUpFormControls.tribal.setValidators([
          Validators.maxLength(100),
        ]);
        this.signUpFormControls.tribalName.setValidators([
          Validators.required,
          Validators.maxLength(100),
        ]);
        this.signUpFormControls.tribal.updateValueAndValidity();
        this.signUpFormControls.tribalName.updateValueAndValidity();
        return true;
      }
    }
    this.signUpForm.patchValue({
      tribalName: "",
      tribal: "",
    });
    this.signUpFormControls.tribal.clearValidators();
    this.signUpFormControls.tribalName.clearValidators();
    this.signUpFormControls.tribal.updateValueAndValidity();
    this.signUpFormControls.tribalName.updateValueAndValidity();
    return false;
  }
  onFocusOutEvent(event: any) {
    if (
      this.signUpFormControls.county.value === null &&
      this.signUpFormControls.countyName.value !== null
    ) {
      this.signUpFormControls["countyName"].setErrors({ incorrect: true });
    }
  }

  switchLang(lang: string) {
    if (lang === "en" || lang === "es") {
      this.translate.use(lang);
      this.signUpDataService.updateLanguage(lang);
    }
  }

  onChangeCard() {
    this.cardSelection =
      this.signUpFormControls.newCard.value == "true" ? true : false;
  }
  save() {
    this.submit();
  }
  cancel() {
    this.formCancel.emit();
    this.router.navigate([`/auth/sign-up/${this.stateObj.programCode.toLowerCase()}`]);
    this.modalService.dismissAll();
  }
  edit() {
    this.formEdit.emit();
    this.modalService.dismissAll();
  }
}
