import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { environment } from "src/environments/environment";
import { LoaderService } from "../loading/loader.service";
import { NotificationsService } from "../notifications/notifications.service";
import { AuthenticationService } from "../services/authentication.service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    public router: Router,
    private authenticationService: AuthenticationService,
    private notifications: NotificationsService,
    private loaderService: LoaderService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUser();

    if (currentUser) {
      const roles = route.data["roles"] as Array<string>;
      if (roles && roles.length > 0) {
        let isInRole = true;
        // roles.forEach(
        //   (role) => (isInRole = isInRole || currentUser.isInRole(role))
        // );
        if (!isInRole) {
          this.notifications.error(
            "Access Denied",
            "You do not have permission"
          );
          if (state.url != "/portal/home") {
            this.router.navigate(["/portal/home"]);
          } else {
            this.router.navigate(["/auth/login"]);
          }
        }

        const now = new Date();
        const expired = new Date(currentUser.expiresTokenAt);
        var seconds = (now.getTime() - expired.getTime()) / 1000;
        if (seconds > currentUser.secondsRefreshToken) {
          this.notifications.error(
            "Authentication Required",
            "Please sign in to access this page"
          );
          this.authenticationService.clearStorage();
          this.router.navigate(["/auth/login"]);
        }

        return isInRole;
      }

      return true;
    }

    this.authenticationService.clearStorage();
    this.router.navigate(["/auth/login"]);
    return false;
  }

  static validateRoute() {
    const pathName = globalThis.location.pathname;
    const stateName = pathName.slice(-2);
    const result = environment.program.filter(e => e.programCode.toLowerCase() === stateName.toLowerCase());
    if(result.length > 0) {
      return true;
    }
    return false;
  }
}
