import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import * as lpn from "google-libphonenumber";
import moment from "moment";
import { TypeaheadMatch } from "ngx-bootstrap/typeahead";
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from "ngx-intl-tel-input";
import { Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { NotificationsService } from "src/app/core/notifications/notifications.service";
import { GenericFunctionsService } from "src/app/core/services/generic-functions.service";
import { LookupService } from "src/app/core/services/lookup.service";
import { ParticipantSignUpService } from "src/app/core/services/participant-sign-up.service";
import { LookupValueDTO } from "src/app/dtos/lookupValueDTO";
//import { SignUpResponse } from "src/app/dtos/signUpResponse";
import { AdditionalProgramEligibilityDTO } from "src/app/dtos/additionalProgramEligibilityDTO";
import { SignUpResponse } from "src/app/dtos/signUpResponse";
import { SignUpValidateAccountCommand } from "src/app/dtos/signUpValidateAccountCommand";
import { environment } from "src/environments/environment";
import { SignUpDataService } from "../sign-up-data.service";

export function olderThan(years = 10): ValidatorFn {
  return function validate(control: AbstractControl) {
    const age = moment().diff(moment(control.value), "years");
    const isMajor = age >= years;
    if (!isMajor) {
      return {
        major: {
          major: years,
        },
      };
    }
    return null;
  };
}
@Component({
  selector: "app-sign-up-state",
  templateUrl: "./sign-up-state.component.html",
  styleUrls: ["./sign-up-state.component.scss"],
})
export class SignUpStateComponent implements OnInit {
  @ViewChild("signUpUpdatesModal") signUpUpdatesModal: ElementRef;

  routeState: string;

  signUpForm: FormGroup;

  errors: { [key: string]: string[] };
  minDate = moment().subtract(122, "years").toDate();
  maxDate = new Date();

  dataSourceState: Observable<LookupValueDTO[]>;
  typeaheadStateLoading = false;
  typeaheadStateNoResults = false;
  selectedState;

  dataSourceCounty: Observable<LookupValueDTO[]>;
  typeaheadCountyLoading = false;
  typeaheadCountyNoResults = false;

  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates];
  phoneUtil: any = lpn.PhoneNumberUtil.getInstance();

  checked: boolean = false;
  ethnicItems: LookupValueDTO[];
  anio: number = new Date().getFullYear();
  cardSelection = false;
  stateList = [
    {
      id: "CT",
      description: "CT-Connecticut",
      name: "Connecticut",
    },
    {
      id: "NM",
      description: "New Mexico",
      name: "New Mexico",
    },
  ];
  additionalProgramEligibilityList = [
    {
      id: "CSFP",
      name: "CSFP (Commodity Supplemental Food Program)",
    },
    {
      id: "Medicaid",
      name: "Medicaid",
    },
    {
      id: "SNAP",
      name: "SNAP (Supplemental Nutrition Assistance Program)",
    },
    {
      id: "SSI",
      name: "SSI (Supplemental Security Income)",
    },
    {
      id: "TANF",
      name: "TANF (Temporary Assistance for Needy Families)",
    },
    {
      id: "TEFAP",
      name: "TEFAP (Temporary Emergency Food Assistance Program)",
    },
  ];
  // siteKey = environment.recaptcha.siteKey;
  raceItems: LookupValueDTO[];
  dataSourceTribal: Observable<LookupValueDTO[]>;
  typeaheadTribalLoading = false;
  typeaheadTribalNoResults = false;

  additionalProgramEligibilityItems: AdditionalProgramEligibilityDTO[];

  modalRef: NgbModalRef;
  closeResult: string;
  stateObj = {
    state: '',
    stateName: '',
    programCode: ''
  }
  imgPath: string;

  constructor(
    private fb: FormBuilder,
    private lookupService: LookupService,
    public genericFunctions: GenericFunctionsService,
    private participantSignUpService: ParticipantSignUpService,
    private router: Router,
    private notifications: NotificationsService,
    public translate: TranslateService,
    private signUpDataService: SignUpDataService,
    private modalService: NgbModal,
    private participantService: ParticipantSignUpService,
    private readonly route: ActivatedRoute,
  ) {
    this.route.params.subscribe((params) => {
      if (params.routeState) {
        this.routeState = params.routeState;
        this.stateObj.programCode = this.routeState.slice(-2);
      }
    });
    translate.addLangs(["en", "es"]);
    translate.setDefaultLang("en");
    this.translate.currentLang = "en";
    this.onLoadData();
  }

  ngOnInit(): void {
    //this.translate.store.currentLang = "en";
    this.onLoadData();
  }

  onCheckboxClicked() {
    const certifyCheck = document.querySelector(".certify-check");
    const certifyCheck1 = document.querySelector(".certify-check1");
    if (this.signUpFormControls.certify.value == true) {
      certifyCheck.classList.add("checked");
    } else if (this.signUpFormControls.certify.value == false) {
      certifyCheck.classList.remove("checked");
    }
    if (this.signUpFormControls.certifyDual.value == true) {
      certifyCheck1.classList.add("checked");
    } else if (this.signUpFormControls.certifyDual.value == false) {
      certifyCheck1.classList.remove("checked");
    }
  }

  onLoadData() {
    this.signUpForm = this.fb.group({
      programCode: this.stateObj.programCode,
      translate: this.translate.store.currentLang === "en" ? "en" : "es",
      certify: ["", Validators.compose([Validators.required])],
      certifyDual: ["", Validators.compose([Validators.required])],

      firstName: [
        "",
        Validators.compose([Validators.required, Validators.maxLength(100)]),
      ],
      middleName: ["", Validators.compose([Validators.maxLength(100)])],
      lastName: [
        "",
        Validators.compose([Validators.required, Validators.maxLength(100)]),
      ],
      dateOfBirth: ["", Validators.compose([Validators.required])],
      gender: ["", Validators.compose([Validators.required])],
      state: [""],
      stateName: [""],
      city: [
        "",
        Validators.compose([Validators.required, Validators.maxLength(50)]),
      ],
      zipCode: [
        "",
        Validators.compose([
          Validators.required,
          Validators.maxLength(10),
          Validators.pattern("^[0-9]{5}(?:-[0-9]{4})?$"),
        ]),
      ],
      addressLine: [
        "",
        Validators.compose([Validators.required, Validators.maxLength(100)]),
      ],
      addressLine2: ["", Validators.compose([Validators.maxLength(100)])],
      county: [
        "",
        Validators.compose([Validators.required, Validators.maxLength(100)]),
      ],
      countyName: [
        "",
        Validators.compose([Validators.required, Validators.maxLength(100)]),
      ],
      email: [
        "",
        Validators.compose([
          Validators.pattern(
            "[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}"
          ),
          Validators.maxLength(100),
        ]),
      ],
      phoneNumber: ["", Validators.required],
      ethnics: ["", Validators.required],
      numberInHousehold: ["0", Validators.compose([Validators.required])],
      grossMonthlyIncome: ["", Validators.compose([Validators.required])],
      newCard: ["", Validators.compose([Validators.required])],
      cardNumber: ["", Validators.compose([Validators.maxLength(16)])],
      proxyName: ["", Validators.compose([Validators.maxLength(100)])],
      language: ["", Validators.compose([Validators.required])],
      race: ["", Validators.compose([Validators.required])],
      tribal: [""],
      tribalName: [""],
      additionalProgramEligibility: this.fb.array([]),
      externalUserId: ["", Validators.compose([Validators.required, Validators.maxLength(255)])],

      // recaptcha: ["", Validators.required],
    });
    const stateResult = environment.program.filter(e => e.programCode.toLowerCase() === this.stateObj?.programCode.toLowerCase());
    if(stateResult.length > 0) {
      this.stateObj = stateResult[0];
      this.imgPath = `assets/img/${this.stateObj.programCode.toLowerCase()}/${this.stateObj.programCode.toLowerCase()}.png`;
    }

    this.additionalProgramEligibilityFormArray.clear();
    for (let i = 0; i < 6; i++)
      this.additionalProgramEligibilityFormArray.push(new FormControl());

    // this.dataSourceState = new Observable((observer: any) => {
    //   this.signUpForm.patchValue({ state: null });
    //   observer.next(this.signUpFormControls.stateName.value);
    // }).pipe(
    //   mergeMap((token: string) => this.lookupService.searchStates(token))
    // );

    this.dataSourceCounty = new Observable((observer: any) => {
      this.signUpForm.patchValue({ county: null });
      observer.next(this.signUpFormControls.countyName.value);
    }).pipe(
      mergeMap((token: string) => this.lookupService.searchCounties(token, this.stateObj?.state))
    );
    this.dataSourceTribal = new Observable((observer: any) => {
      this.signUpForm.patchValue({ tribal: null });
      observer.next(this.signUpFormControls.tribalName.value);
    }).pipe(
      mergeMap((token: string) => this.lookupService.searchTribals(token))
    );

    this.lookupService.searchAllRaces().subscribe((response) => {
      this.raceItems = response;
    });

    if (environment.development) {
      this.signUpForm.patchValue({
        firstName: "Signup",
        middleName: "Dami",
        lastName: "Caba",
        dateOfBirth: "01/01/1991",
        gender: "M",
        stateName: "",
        state: "",
        city: "Santa Fe",
        zipCode: "30000",
        addressLine: "Santiago del estero 2212",
        county: "BE",
        countyName: "Bernalillo County",
        email: "qwerty" + Math.floor(1000 + Math.random() * 9000) + "@mail.com",
        phoneNumber: "1231231" + Math.floor(1000 + Math.random() * 9000),
        ethnics: ["AA"],
        proxyName: "123",
        numberInHousehold: 1,
        grossMonthlyIncome: 100,
      });
    }
    if(this.stateObj.programCode.toLowerCase() === 'nm') {
      this.signUpForm.disable();
    }
  }

  submit() {
    if (this.signUpForm.valid) {
      this.signUpForm.patchValue({
        state: this.stateObj.state,
        stateName: this.stateObj.stateName,
        programCode: this.stateObj.programCode,
      })
      const dto:SignUpValidateAccountCommand = {
        cardNumber: this.signUpFormControls.cardNumber.value ? this.signUpFormControls.cardNumber.value.replace(/\s/g, "") : null,
      }
      if(this.signUpFormControls.newCard.value == "true") {
        this.participantService.validateCardAccount(dto).subscribe((result: SignUpResponse) => {
          this.signUpDataService.updateUser(this.signUpForm.value);
          this.signUpDataService.updateLanguage(this.translate.store.currentLang);
          this.openSignUpUpdatesModal(this.signUpUpdatesModal);
        }, (error) => {
          if(error?.text == 'NO_ACCOUNT'){
            this.notifications
            .confirm(
              this.translate.store.currentLang == "en"
                ? "ATTENTION"
                : "ATENCIÓN",
              this.translate.store.currentLang == "en"
                ? "No account was found with the card number provided."
                : "No se encontró ninguna cuenta con el número de tarjeta proporcionado.",
              this.translate.store.currentLang == "en" ? "Close" : "Cerrar"
            )
            .then(
              () => {
                
              },
              () => {
                
              }
            );
          } else if(error?.text == 'ACTIVE_ACCOUNT') {
            this.notifications
          .confirm(
            this.translate.store.currentLang == "en"
              ? "ATTENTION"
              : "ATENCIÓN",
            this.translate.store.currentLang == "en"
              ? "This account is already active. Please contact your Program Administrators."
              : "Esta cuenta ya está activa. Comuníquese con los administradores de su programa.",
            this.translate.store.currentLang == "en" ? "Close" : "Cerrar"
          )
          .then(
            () => {
              
            },
            () => {
              
            }
          );
          }
        });
      } else {
        this.signUpDataService.updateUser(this.signUpForm.value);
        this.signUpDataService.updateLanguage(this.translate.store.currentLang);
        this.openSignUpUpdatesModal(this.signUpUpdatesModal);
      }     
    }
  }

  public get signUpFormControls(): any {
    return this.signUpForm.controls;
  }

  public get additionalProgramEligibilityFormArray(): FormArray {
    return this.signUpForm.get("additionalProgramEligibility") as FormArray;
  }

  public changeTypeaheadStateLoading(e: boolean): void {
    this.typeaheadStateLoading = e;
  }

  public changeTypeaheadStateNoResults(e: boolean): void {
    this.typeaheadStateNoResults = e;
  }

  public typeaheadStateOnSelect(e: TypeaheadMatch): void {
    this.signUpForm.patchValue({
      state: e.item.id,
      stateName: e.item.name,
    });
  }

  public changeTypeaheadCountyLoading(e: boolean): void {
    this.typeaheadCountyLoading = e;
  }

  public changeTypeaheadCountyNoResults(e: boolean): void {
    this.typeaheadCountyNoResults = e;
  }

  public typeaheadCountyOnSelect(e: TypeaheadMatch): void {
    this.signUpForm.patchValue({
      county: e.item.id,
      countyName: e.item.name,
    });
  }
  public changeTypeaheadTribalLoading(e: boolean): void {
    this.typeaheadTribalLoading = e;
  }

  public changeTypeaheadTribalNoResults(e: boolean): void {
    this.typeaheadTribalNoResults = e;
  }

  public typeaheadTribalOnSelect(e: TypeaheadMatch): void {
    this.signUpForm.patchValue({
      tribal: e.item.id,
      tribalName: e.item.name,
    });
  }
  displayTribal(filter: any) {
    let vals: string[] = filter;
    if (vals.length > 0) {
      let count = 0;
      for (var item in vals) {
        if (vals[item] === "R-AIA") {
          count++;
        }
      }
      if (count == 1) {
        this.signUpFormControls.tribal.clearValidators();
        this.signUpFormControls.tribalName.clearValidators();
        this.signUpFormControls.tribal.setValidators([
          Validators.maxLength(100),
        ]);
        this.signUpFormControls.tribalName.setValidators([
          Validators.required,
          Validators.maxLength(100),
        ]);
        this.signUpFormControls.tribal.updateValueAndValidity();
        this.signUpFormControls.tribalName.updateValueAndValidity();
        return true;
      }
    }
    this.signUpForm.patchValue({
      tribalName: "",
      tribal: "",
    });
    this.signUpFormControls.tribal.clearValidators();
    this.signUpFormControls.tribalName.clearValidators();
    this.signUpFormControls.tribal.updateValueAndValidity();
    this.signUpFormControls.tribalName.updateValueAndValidity();
    return false;
  }
  onFocusOutEvent(event: any) {
    if (
      this.signUpFormControls.county.value === null &&
      this.signUpFormControls.countyName.value !== null
    ) {
      this.signUpFormControls["countyName"].setErrors({ incorrect: true });
    }
  }

  switchLang(lang: string) {
    if (lang === "en" || lang === "es") {
      this.translate.use(lang);
      this.signUpDataService.updateUser(this.signUpForm.value);
      this.signUpDataService.updateLanguage(lang);
    }
  }

  onChangeCard() {
    this.cardSelection =
      this.signUpFormControls.newCard.value == "true" ? true : false;
    if(!this.cardSelection){
      this.signUpForm.patchValue({
        cardNumber: ""
      });
    }else {
      this.signUpFormControls.cardNumber.setValidators([
        Validators.required,
        Validators.maxLength(16)
      ]);
      this.signUpFormControls.cardNumber.updateValueAndValidity();
    }
  }

  openSignUpUpdatesModal(content) {
    this.modalService.open(content, {
      size: "lg",
      backdrop: "static",
      keyboard: false,
    });
  }

  onSignUpUpdatesSubmit() {
    this.modalService.dismissAll();
    this.signUpForm.reset();
    this.signUpForm.patchValue({
      race: "",
    });
    this.signUpFormControls.race.clearValidators();
    this.signUpFormControls.race.updateValueAndValidity();
  }
  onSignUpUpdatesEdit() {
    this.modalService.dismissAll();
  }
  onSignUpUpdatesCancel() {
    this.signUpForm.reset();
    this.signUpForm.patchValue({
      race: "",
    });
    this.signUpFormControls.race.clearValidators();
    this.signUpFormControls.race.updateValueAndValidity();
  }
}
