<div class="wrapper">
    <div class="block-center mt-4 wd-responsive">
      <!-- START card-->
      <div class="card card-flat">
        <div class="card-header text-center bg-primary">
          <img
            class="solimarket"
            src="/assets/img/solimarket-portal.png"
            alt="image"
          />
        </div>
        <div [ngSwitch]="stateObj.programCode.toLowerCase()">
          <div *ngSwitchCase="'la'">
            <div class="card-header-logo">
              <img
                class="solimarket-logo-la"
                src="{{imgPath}}"
                alt="program"
              />
              <div><p><strong>{{ "logoTextLa" | translate}}</strong></p></div>
            </div>
          </div>
          <div *ngSwitchCase="'nm'">
            <div class="card-header-logo bg-white mt-1 col-md-12">
              <img
                class="solimarket-logo-nm"
                src="{{imgPath}}"
                alt="program"
              />
              <div class="row"><div class="card-header-nm-title col-md-12" [innerHTML]="'nmH1' | translate"></div></div>
              <div class="row"><div class="card-header-nm-title col-md-12"><p [innerHTML]="'nmTitle1' | translate"></p></div></div>
              <div class="row"><div class="card-header-nm-title col-md-12" [innerHTML]="'nmH2' | translate"></div></div>
              <div class="row"><div class="card-header-nm-title col-md-12"><p [innerHTML]="'nmTitle2' | translate"></p></div></div>
              
            </div>
          </div>
          <div *ngSwitchCase="'cn'">
            <div class="card-header-logo">
              <img
                class="solimarket-logo-cn"
                src="{{imgPath}}"
                alt="program"
              />
              <div><p><strong>{{ "cnTitle" | translate}}</strong></p></div>
            </div>
          </div>
          <div *ngSwitchDefault>
            <div class="card-header-logo bg-white mt-1">
              <img
                class="solimarket-logo"
                src="{{imgPath}}"
                alt="program"
              />
            </div>
          </div>
        </div>
        
        

        
        
        <!-- <p class="title">Sign Up</p> -->
  
        <div class="card-body" style="padding-top: 2em !important">
          <form [formGroup]="signUpForm" class="form-validate mb-3">
            <div class="row mb-3">
              <div class="col-md-8">
                <span class="form-inline">
                  <label class="mr-5 text-info lead text-bold">{{
                    "selectLang" | translate
                  }}</label>
                </span>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-8">
                <span class="form-inline">
                  <select
                    class="form-control"
                    #selectedLang
                    (change)="switchLang(selectedLang.value)"
                  >
                    <option
                      *ngFor="let language of translate.getLangs()"
                      [value]="language"
                      [selected]="language === translate.currentLang"
                    >
                      {{ language == "en" ? "English" : "Español" }}
                    </option>
                  </select>
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group text-center">
                  <!-- <label class="restrict-sign-up">{{
                    "restrictSignUp" | translate
                  }}</label> -->
                  <label class="mr-5 text-info text-bold">{{
                    "agreeMsg" | translate
                  }}</label>
                </div>
                <label class="mr-5 text-info text-bold">{{
                  "participantRights" | translate
                }}</label>
                <label class="c-checkbox">
                  <label class="certify-description inline-label">
                    <input
                      type="checkbox"
                      name="certify"
                      value="true"
                      (change)="onCheckboxClicked()"
                      formControlName="certify"
                    />
                    <span class="fa fa-check certify-check"></span>
                    {{ "certify" | translate }}
                  </label>
                  <a
                    href="https://www.ecfr.gov/current/title-7/subtitle-B/chapter-II/subchapter-A/part-249"
                    class="certify-link inline-link"
                    target="_blank"
                  >
                    {{ "certifyUrl" | translate }}
                  </a>
                </label>
                <label class="mr-5 text-info text-bold">{{
                  "participantDual" | translate
                }}</label>
                <label class="c-checkbox">
                  <label class="certify-description inline-label">
                    <input
                      type="checkbox"
                      name="certifyDual"
                      value="true"
                      (change)="onCheckboxClicked()"
                      formControlName="certifyDual"
                    />
                    <span class="fa fa-check certify-check1"></span>
                    {{ "certifyDual" | translate }}
                  </label>
                </label>
                <div class="mr-5 text-center text-info">
                  <b class="certify-declaration">{{ "label8" | translate }}</b>
                </div>
                <div
                  class="text-danger ml-2 mt-2"
                  *ngIf="
                    signUpForm.controls['certify'].hasError('required') &&
                    (signUpForm.controls['certify'].dirty ||
                      signUpForm.controls['certify'].touched)
                  "
                >
                  {{ "mustCerify" | translate }}
                </div>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="required"
                    ><strong>{{ "stillCard" | translate }}</strong></label
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <div class="input-group with-focus">
                    <label class="c-radio p-2">
                      <input
                        type="radio"
                        name="newCard"
                        value="true"
                        formControlName="newCard"
                        (change)="onChangeCard()"
                      />
                      <span class="fa fa-check"></span>
                      {{ "yes" | translate }}
                    </label>
                    <label class="c-radio p-2">
                      <input
                        type="radio"
                        name="newCard"
                        value="false"
                        formControlName="newCard"
                        (change)="onChangeCard()"
                      />
                      <span class="fa fa-check"></span>
                      {{ "no" | translate }}
                    </label>
                  </div>
                  <div
                    class="text-danger ml-2 mt-2"
                    *ngIf="
                      signUpForm.controls['newCard'].hasError('required') &&
                      (signUpForm.controls['newCard'].dirty ||
                        signUpForm.controls['newCard'].touched)
                    "
                  >
                    {{ "fieldRedMsg" | translate }}
                  </div>
                  <div class="text-danger ml-2 mt-2">
                    {{ errors?.newCard }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="cardSelection">
              <div class="col-md-12">
                <label class="required">{{ "enterCard" | translate }}</label>
                <div class="input-group with-focus">
                  <input
                    id="cc-number"
                    autocomplete="nope"
                    formControlName="cardNumber"
                    type="tel"
                    class="form-control cc-number"
                    placeholder="•••• •••• •••• ••••"
                    style="font-size: 20px"
                    mask="0000 0000 0000 0000"
                  />
                </div>
                <div
                  class="text-danger ml-2 mt-2"
                  *ngIf="
                    (signUpForm.controls['cardNumber'].dirty &&
                    signUpForm.controls['cardNumber'].invalid &&
                      signUpForm.controls['cardNumber'].touched)
                  "
                >
                  {{ "16CharMsg" | translate }}
                </div>
                <div class="text-danger ml-2 mt-2">
                  {{ errors?.cardNumber }}
                </div>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <div class="input-group with-focus">
                    <label class="c-radio p-2">
                      <input
                        type="radio"
                        name="newCard"
                        value="true"
                        formControlName="newCard"
                        (change)="onChangeCard()"
                      />
                      <span class="fa fa-check"></span>
                      {{ "yes" | translate }}
                    </label>
                    <label class="c-radio p-2">
                      <input
                        type="radio"
                        name="newCard"
                        value="false"
                        formControlName="newCard"
                        (change)="onChangeCard()"
                      />
                      <span class="fa fa-check"></span>
                      {{ "no" | translate }}
                    </label>
                  </div>
                  <div
                    class="text-danger ml-2 mt-2"
                    *ngIf="
                      signUpForm.controls['newCard'].hasError('required') &&
                      (signUpForm.controls['newCard'].dirty ||
                        signUpForm.controls['newCard'].touched)
                    "
                  >
                    {{ "fieldRedMsg" | translate }}
                  </div>
                  <div class="text-danger ml-2 mt-2">
                    {{ errors?.newCard }}
                  </div>
                </div>
              </div>
            </div> -->
            <hr />
  
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="required">{{ "firstName" | translate }}</label>
                  <div class="input-group with-focus">
                    <input
                      autocomplete="nope"
                      type="text"
                      class="form-control"
                      formControlName="firstName"
                    />
                  </div>
                  <div
                    class="text-danger ml-2 mt-2"
                    *ngIf="
                      signUpForm.controls['firstName'].hasError('required') &&
                      (signUpForm.controls['firstName'].dirty ||
                        signUpForm.controls['firstName'].touched)
                    "
                  >
                    {{ "fieldRedMsg" | translate }}
                  </div>
                  <div
                    class="text-danger ml-2 mt-2"
                    *ngIf="
                      signUpForm.controls['firstName'].hasError('maxlength') &&
                      (signUpForm.controls['firstName'].dirty ||
                        signUpForm.controls['firstName'].touched)
                    "
                  >
                    {{ "100CharLengthMsg" | translate }}
                  </div>
                  <div class="text-danger ml-2 mt-2">
                    {{ errors?.firstName }}
                  </div>
                </div>
              </div>
  
              <div class="col-md-4">
                <div class="form-group">
                  <label>{{ "middleName" | translate }}</label>
                  <div class="input-group with-focus">
                    <input
                      autocomplete="nope"
                      type="text"
                      class="form-control"
                      formControlName="middleName"
                    />
                  </div>
                  <div
                    class="text-danger ml-2 mt-2"
                    *ngIf="
                      signUpForm.controls['middleName'].hasError('maxlength') &&
                      (signUpForm.controls['middleName'].dirty ||
                        signUpForm.controls['middleName'].touched)
                    "
                  >
                    {{ "100CharLengthMsg" | translate }}
                  </div>
                  <div class="text-danger ml-2 mt-2">
                    {{ errors?.middleName }}
                  </div>
                </div>
              </div>
  
              <div class="col-md-4">
                <div class="form-group">
                  <label class="required">{{ "lastName" | translate }}</label>
                  <div class="input-group with-focus">
                    <input
                      autocomplete="nope"
                      type="text"
                      class="form-control"
                      formControlName="lastName"
                    />
                  </div>
                  <div
                    class="text-danger ml-2 mt-2"
                    *ngIf="
                      signUpForm.controls['lastName'].hasError('required') &&
                      (signUpForm.controls['lastName'].dirty ||
                        signUpForm.controls['lastName'].touched)
                    "
                  >
                    {{ "fieldRedMsg" | translate }}
                  </div>
                  <div
                    class="text-danger ml-2 mt-2"
                    *ngIf="
                      signUpForm.controls['lastName'].hasError('maxlength') &&
                      (signUpForm.controls['lastName'].dirty ||
                        signUpForm.controls['lastName'].touched)
                    "
                  >
                    {{ "100CharLengthMsg" | translate }}
                  </div>
                  <div class="text-danger ml-2 mt-2">
                    {{ errors?.lastName }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="required">{{ "dateOfBirth" | translate }}</label>
                  <div class="input-group with-focus">
                    <input
                      autocomplete="nope"
                      [minDate]="minDate"
                      [maxDate]="maxDate"
                      class="form-control"
                      formControlName="dateOfBirth"
                      bsDatepicker
                      placeholder="mm-dd-yyyy"
                    />
                  </div>
                  <div
                    class="text-danger ml-2 mt-2"
                    *ngIf="
                      signUpForm.controls['dateOfBirth'].hasError('required') &&
                      (signUpForm.controls['dateOfBirth'].dirty ||
                        signUpForm.controls['dateOfBirth'].touched)
                    "
                  >
                    {{ "fieldRedMsg" | translate }}
                  </div>
                  <div class="text-danger ml-2 mt-2">
                    {{ errors?.dateOfBirth }}
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <div class="form-group">
                  <label class="required">{{ "gender" | translate }}</label>
                  <div class="input-group with-focus">
                    <label class="c-radio p-2">
                      <input
                        type="radio"
                        name="gender"
                        value="F"
                        formControlName="gender"
                      />
                      <span class="fa fa-check"></span>
                      {{ "female" | translate }}
                    </label>
                    <label class="c-radio p-2">
                      <input
                        type="radio"
                        name="gender"
                        value="M"
                        formControlName="gender"
                      />
                      <span class="fa fa-check"></span>
                      {{ "male" | translate }}
                    </label>
                    <label class="c-radio p-2">
                      <input
                        type="radio"
                        name="gender"
                        value="NB"
                        formControlName="gender"
                      />
                      <span class="fa fa-check"></span>
                      {{ "nonBinary" | translate }}
                    </label>
                    <label class="c-radio p-2">
                      <input
                        type="radio"
                        name="gender"
                        value="NA"
                        formControlName="gender"
                      />
                      <span class="fa fa-check"></span>
                      {{ "notAny" | translate }}
                    </label>
                  </div>
                  <div
                    class="text-danger ml-2 mt-2"
                    *ngIf="
                      signUpForm.controls['gender'].hasError('required') &&
                      (signUpForm.controls['gender'].dirty ||
                        signUpForm.controls['gender'].touched)
                    "
                  >
                    {{ "fieldRedMsg" | translate }}
                  </div>
                  <div class="text-danger ml-2 mt-2">
                    {{ errors?.gender }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8">
                <div class="form-group">
                  <label class="required">{{ "language" | translate }}</label>
                  <div class="input-group with-focus">
                    <label class="c-radio p-2">
                      <input
                        type="radio"
                        name="language"
                        value="EN"
                        formControlName="language"
                      />
                      <span class="fa fa-check"></span>
                      {{ "english" | translate }}
                    </label>
                    <label class="c-radio p-2">
                      <input
                        type="radio"
                        name="language"
                        value="ES"
                        formControlName="language"
                      />
                      <span class="fa fa-check"></span>
                      {{ "spanish" | translate }}
                    </label>
  
                    <label class="c-radio p-2">
                      <input
                        type="radio"
                        name="language"
                        value="HT"
                        formControlName="language"
                      />
                      <span class="fa fa-check"></span>
                      {{ "haitianCreole" | translate }}
                    </label>
                    <label class="c-radio p-2">
                      <input
                        type="radio"
                        name="language"
                        value="OT"
                        formControlName="language"
                      />
                      <span class="fa fa-check"></span>
                      {{ "other" | translate }}
                    </label>
                  </div>
                  <div
                    class="text-danger ml-2 mt-2"
                    *ngIf="
                      signUpForm.controls['language'].hasError('required') &&
                      (signUpForm.controls['language'].dirty ||
                        signUpForm.controls['language'].touched)
                    "
                  >
                    This field is required
                  </div>
                  <div class="text-danger ml-2 mt-2">
                    {{ errors?.language }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="required">CN Registration Number</label>
                  <div class="input-group with-focus">
                    <input
                      autocomplete="nope"
                      type="text"
                      class="form-control"
                      formControlName="externalUserId"
                    />
                  </div>
                  <div
                    class="text-danger ml-2 mt-2"
                    *ngIf="
                      signUpForm.controls['externalUserId'].hasError('required') &&
                      (signUpForm.controls['externalUserId'].dirty ||
                        signUpForm.controls['externalUserId'].touched)
                    "
                  >
                    {{ "fieldRedMsg" | translate }}
                  </div>
                  <div
                    class="text-danger ml-2 mt-2"
                    *ngIf="
                      signUpForm.controls['externalUserId'].hasError('maxlength') &&
                      (signUpForm.controls['externalUserId'].dirty ||
                      signUpForm.controls['externalUserId'].touched)
                    "
                  >
                  {{ "30CharMsg" | translate }}
                  </div>
                  <div class="text-danger ml-2 mt-2">
                    {{ errors?.externalUserId }}
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <label class="certify-description">{{
              "validAdressMsg" | translate
            }}</label>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="required">{{
                    "mailingAddress" | translate
                  }}</label>
                  <div class="input-group with-focus">
                    <input
                      autocomplete="nope"
                      type="text"
                      class="form-control"
                      formControlName="addressLine"
                    />
                  </div>
                  <div
                    class="text-danger ml-2 mt-2"
                    *ngIf="
                      signUpForm.controls['addressLine'].hasError('required') &&
                      (signUpForm.controls['addressLine'].dirty ||
                        signUpForm.controls['addressLine'].touched)
                    "
                  >
                    {{ "fieldRedMsg" | translate }}
                  </div>
                  <div
                    class="text-danger ml-2 mt-2"
                    *ngIf="
                      signUpForm.controls['addressLine'].hasError('maxlength') &&
                      (signUpForm.controls['addressLine'].dirty ||
                        signUpForm.controls['addressLine'].touched)
                    "
                  >
                    {{ "100CharLengthMsg" | translate }}
                  </div>
                  <div class="text-danger ml-2 mt-2">
                    {{ errors?.addressLine }}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>{{ "address2" | translate }}</label>
                  <div class="input-group with-focus">
                    <input
                      autocomplete="nope"
                      class="form-control"
                      formControlName="addressLine2"
                    />
                  </div>
                  <div
                    class="text-danger ml-2 mt-2"
                    *ngIf="
                      signUpForm.controls['addressLine2'].hasError('maxlength') &&
                      (signUpForm.controls['addressLine2'].dirty ||
                        signUpForm.controls['addressLine2'].touched)
                    "
                  >
                    {{ "100CharLengthMsg" | translate }}
                  </div>
                  <div class="text-danger ml-2 mt-2">
                    {{ errors?.addressLine2 }}
                  </div>
                </div>
              </div>
  
              <div class="col-md-3">
                <div class="form-group">
                  <label class="required">{{ "city" | translate }}</label>
                  <div class="input-group with-focus">
                    <input
                      autocomplete="nope"
                      type="text"
                      class="form-control"
                      formControlName="city"
                    />
                  </div>
                  <div
                    class="text-danger ml-2 mt-2"
                    *ngIf="
                      signUpForm.controls['city'].hasError('required') &&
                      (signUpForm.controls['city'].dirty ||
                        signUpForm.controls['city'].touched)
                    "
                  >
                    {{ "fieldRedMsg" | translate }}
                  </div>
                  <div
                    class="text-danger ml-2 mt-2"
                    *ngIf="
                      signUpForm.controls['city'].hasError('maxlength') &&
                      (signUpForm.controls['city'].dirty ||
                        signUpForm.controls['city'].touched)
                    "
                  >
                    {{ "100CharLengthMsg" | translate }}
                  </div>
                  <div class="text-danger ml-2 mt-2">{{ errors?.city }}</div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>{{ "state" | translate }}</label>
                  <div><label>{{stateObj.stateName}}</label></div>
                  
                  <!-- <div class="input-group with-focus">
                    <input
                      autocomplete="nope"
                      [typeahead]="stateList"
                      (typeaheadLoading)="changeTypeaheadStateLoading($event)"
                      (typeaheadNoResults)="changeTypeaheadStateNoResults($event)"
                      (typeaheadOnSelect)="typeaheadStateOnSelect($event)"
                      [typeaheadOptionField]="'name'"
                      [typeaheadWaitMs]="500"
                      class="form-control"
                      [typeaheadMinLength]="0"
                      formControlName="stateName"
                    />
                    <div *ngIf="typeaheadStateLoading === true">
                      <i class="fas fa-sync"></i>
                    </div>
                  </div>
                  <div *ngIf="typeaheadStateNoResults === true" class="">
                    <i class="fa fa-times"></i> {{ "noResultMsg" | translate }}
                  </div>
                  <div
                    class="text-danger ml-2 mt-2"
                    *ngIf="
                      signUpForm.controls['stateName'].hasError('required') &&
                      (signUpForm.controls['stateName'].dirty ||
                        signUpForm.controls['stateName'].touched)
                    "
                  >
                    {{ "fieldRedMsg" | translate }}
                  </div>
                  <div
                    class="text-danger ml-2 mt-2"
                    *ngIf="
                      signUpForm.controls['stateName'].hasError('maxlength') &&
                      (signUpForm.controls['stateName'].dirty ||
                        signUpForm.controls['stateName'].touched)
                    "
                  >
                    {{ "50CharMesg" | translate }}
                  </div>
                  <div
                    class="text-danger ml-2 mt-2"
                    *ngIf="
                      signUpForm.controls['state'].hasError('required') &&
                      (signUpForm.controls['stateName'].dirty ||
                        signUpForm.controls['stateName'].touched)
                    "
                  >
                    {{ "selectValue" | translate }}
                  </div>
                  <div class="text-danger ml-2 mt-2">{{ errors?.state }}</div> -->
                </div>
                <!-- <div class="form-group">
                  <label class="required">{{ "state" | translate }}</label>
                  <div class="input-group with-focus">
                    <input
                      autocomplete="nope"
                      type="text"
                      class="form-control"
                      readonly
                      value="New Mexico"
                    />
                  </div>
                </div> -->
              </div>
  
              <div class="col-md-3">
                <div class="form-group">
                  <label class="required">{{ "zipCode" | translate }}</label>
                  <div class="input-group with-focus">
                    <input
                      autocomplete="nope"
                      type="text"
                      class="form-control"
                      formControlName="zipCode"
                    />
                  </div>
                  <div
                    class="text-danger ml-2 mt-2"
                    *ngIf="
                      signUpForm.controls['zipCode'].hasError('required') &&
                      (signUpForm.controls['zipCode'].dirty ||
                        signUpForm.controls['zipCode'].touched)
                    "
                  >
                    {{ "fieldRedMsg" | translate }}
                  </div>
                  <div
                    class="text-danger ml-2 mt-2"
                    *ngIf="
                      signUpForm.controls['zipCode'].hasError('pattern') &&
                      (signUpForm.controls['zipCode'].dirty ||
                        signUpForm.controls['zipCode'].touched)
                    "
                  >
                    {{ "zipFormat" | translate }}
                  </div>
                  <div
                    class="text-danger ml-2 mt-2"
                    *ngIf="
                      signUpForm.controls['zipCode'].hasError('maxlength') &&
                      (signUpForm.controls['zipCode'].dirty ||
                        signUpForm.controls['zipCode'].touched)
                    "
                  >
                    {{ "10CharLengthMsg" | translate }}
                  </div>
                  <div class="text-danger ml-2 mt-2">
                    {{ errors?.zipCode }}
                  </div>
                </div>
              </div>
  
              <div class="col-md-3">
                <div class="form-group">
                  <label class="required">{{ "country" | translate }}</label>
                  <div class="input-group with-focus">
                    <input
                      autocomplete="nope"
                      [typeahead]="dataSourceCounty"
                      (typeaheadLoading)="changeTypeaheadCountyLoading($event)"
                      (typeaheadNoResults)="
                        changeTypeaheadCountyNoResults($event)
                      "
                      (typeaheadOnSelect)="typeaheadCountyOnSelect($event)"
                      [typeaheadOptionField]="'name'"
                      [typeaheadWaitMs]="500"
                      class="form-control county-form-control"
                      [class.error-invalid-county]="typeaheadCountyNoResults"
                      [typeaheadMinLength]="0"
                      formControlName="countyName"
                      (focusout)="onFocusOutEvent($event)"
                    />
                    <div *ngIf="typeaheadCountyLoading === true">
                      <i class="fas fa-sync"></i>
                    </div>
                  </div>
                  <div *ngIf="typeaheadCountyNoResults === true" class="">
                    <i class="fa fa-times"></i> {{ "noResultMsg" | translate }}
                  </div>
  
                  <div
                    class="text-danger ml-2 mt-2"
                    *ngIf="
                      signUpForm.controls['countyName'].hasError('maxlength') &&
                      (signUpForm.controls['countyName'].dirty ||
                        signUpForm.controls['countyName'].touched)
                    "
                  >
                    {{ "100CharLengthMsg" | translate }}
                  </div>
                  <div
                    class="text-danger ml-2 mt-2"
                    *ngIf="
                      signUpForm.controls['countyName'].hasError('required') &&
                      (signUpForm.controls['countyName'].dirty ||
                        signUpForm.controls['countyName'].touched)
                    "
                  >
                    {{ "fieldRedMsg" | translate }}
                  </div>
                  <div
                    class="text-danger ml-2 mt-2"
                    *ngIf="
                      signUpForm.controls['countyName'].value !== null &&
                      signUpForm.controls['county'].value === null &&
                      typeaheadCountyNoResults === false
                    "
                  >
                    {{ "selectCounty" | translate }}
                  </div>
                  <div class="text-danger ml-2 mt-2">
                    {{ errors?.county }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8">
                <div class="form-group">
                  <label>{{ "emailAddress" | translate }}</label>
  
                  <div class="input-group with-focus">
                    <input
                      autocomplete="nope"
                      #emailInput
                      class="form-control"
                      formControlName="email"
                    />
                  </div>
                  <div
                    class="text-danger ml-2 mt-2"
                    *ngIf="
                      signUpForm.controls['email'].hasError('pattern') &&
                      (signUpForm.controls['email'].dirty ||
                        signUpForm.controls['email'].touched)
                    "
                  >
                    {{ "validEmailMsg" | translate }}
                  </div>
                  <div
                    class="text-danger ml-2 mt-2"
                    *ngIf="
                      signUpForm.controls['email'].hasError('maxlength') &&
                      (signUpForm.controls['email'].dirty ||
                        signUpForm.controls['email'].touched)
                    "
                  >
                    {{ "256LengthMsg" | translate }}
                  </div>
                  <div class="text-danger ml-2 mt-2">{{ errors?.email }}</div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="required">{{ "phoneNumber" | translate }}</label>
                  <div class="input-group with-focus">
                    <input
                      class="form-control"
                      phoneMask [preValue]="signUpForm.value.phoneNumber" 
                      formControlName="phoneNumber"
                      maxlength="14"
                      placeholder="(999) 999-9999"
                      
                    />
                  </div>
  
                  <div
                    class="text-danger ml-2 mt-2"
                    *ngIf="
                      signUpForm.controls['phoneNumber'].hasError('required') &&
                      signUpForm.controls['phoneNumber'].dirty &&
                      signUpForm.controls['phoneNumber'].touched
                    "
                  >
                    {{ "fieldRedMsg" | translate }}
                  </div>
                  <div
                    class="text-danger ml-2 mt-2"
                    *ngIf="
                      signUpForm.controls['phoneNumber'].invalid &&
                      signUpForm.controls['phoneNumber'].dirty &&
                      signUpForm.controls['phoneNumber'].touched
                    "
                  >
                    {{ "invalidPhone" | translate }}
                  </div>
                  <div class="text-danger ml-2 mt-2">
                    {{ errors?.phoneNumber }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="required">{{ "ethnicity" | translate }}</label>
                  <div class="input-group with-focus">
                    <label class="c-radio p-2">
                      <input
                        type="radio"
                        name="ethnics"
                        value="HI"
                        formControlName="ethnics"
                      />
                      <span class="fa fa-check"></span>
                      {{ "hispanicorLatino" | translate }}
                    </label>
                    <label class="c-radio p-2">
                      <input
                        type="radio"
                        name="ethnics"
                        value="NHL"
                        formControlName="ethnics"
                      />
                      <span class="fa fa-check"></span>
                      {{ "notHispanicorLatino" | translate }}
                    </label>
                    <label class="c-radio p-2">
                      <input
                        type="radio"
                        name="ethnics"
                        value="NCA"
                        formControlName="ethnics"
                      />
                      <span class="fa fa-check"></span>
                      {{ "notCaptured" | translate }}
                    </label>
                  </div>
                  <div
                    class="text-danger ml-2 mt-2"
                    *ngIf="
                      signUpForm.controls['ethnics'].hasError('required') &&
                      (signUpForm.controls['ethnics'].dirty ||
                        signUpForm.controls['ethnics'].touched)
                    "
                  >
                    This field is required
                  </div>
                  <div class="text-danger ml-2 mt-2">
                    {{ errors?.ethnics }}
                  </div>
                </div>
              </div>
              <div class="col-md-5">
                <div class="form-group">
                  <label class="required">{{ "race" | translate }}</label>
                  <div class="input-group with-focus">
                    <ngx-select
                      [multiple]="true"
                      optionValueField="id"
                      optionTextField="name"
                      [items]="raceItems"
                      placeholder="Select..."
                      [autoClearSearch]="true"
                      formControlName="race"
                      style="width: 100%"
                    >
                    </ngx-select>
                  </div>
                  <div
                    class="text-danger ml-2 mt-2"
                    *ngIf="
                      signUpForm.controls['race'].hasError('required') &&
                      (signUpForm.controls['race'].dirty ||
                        signUpForm.controls['race'].touched)
                    "
                  >
                    This field is required
                  </div>
                  <div class="text-danger ml-2 mt-2">
                    {{ errors?.race }}
                  </div>
                </div>
              </div>
              <div
                class="col-md-3"
                *ngIf="displayTribal(signUpForm.controls['race'].value)"
              >
                <div class="form-group">
                  <label class="required">{{
                    "tribalAffiliation" | translate
                  }}</label>
                  <div class="input-group with-focus">
                    <input
                      autocomplete="nope"
                      [typeahead]="dataSourceTribal"
                      (typeaheadLoading)="changeTypeaheadTribalLoading($event)"
                      (typeaheadNoResults)="
                        changeTypeaheadTribalNoResults($event)
                      "
                      (typeaheadOnSelect)="typeaheadTribalOnSelect($event)"
                      [typeaheadOptionField]="'name'"
                      [typeaheadWaitMs]="500"
                      class="form-control"
                      [typeaheadMinLength]="0"
                      formControlName="tribalName"
                    />
                    <div *ngIf="typeaheadTribalLoading === true">
                      <i class="fas fa-sync"></i>
                    </div>
                  </div>
                  <div *ngIf="typeaheadTribalNoResults === true" class="">
                    <i class="fa fa-times"></i> No Results Found
                  </div>
  
                  <div
                    class="text-danger ml-2 mt-2"
                    *ngIf="
                      signUpForm.controls['tribalName'].hasError('maxlength') &&
                      (signUpForm.controls['tribalName'].dirty ||
                        signUpForm.controls['tribalName'].touched)
                    "
                  >
                    50 characters maximum length
                  </div>
                  <div class="text-danger ml-2 mt-2">
                    {{ errors?.tribal }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label style="font-size: 13px" class="inline-label"
                    ><strong>{{"authorizedProxy" | translate }}</strong></label
                  >
                  <label class="certify-description inline-label">{{
                    "proxy" | translate
                  }}</label>
                  <div class="input-group with-focus">
                    <input
                      autocomplete="nope"
                      type="text"
                      class="form-control"
                      formControlName="proxyName"
                    />
                  </div>
                  <div
                    class="text-danger ml-2 mt-2"
                    *ngIf="
                      signUpForm.controls['proxyName'].hasError('maxlength') &&
                      (signUpForm.controls['proxyName'].dirty ||
                        signUpForm.controls['proxyName'].touched)
                    "
                  >
                    100 characters maximum length
                  </div>
                  <div class="text-danger ml-2 mt-2">
                    {{ errors?.proxyName }}
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <label class="certify-description"
              ><span class="house-hold-label"
                >{{ "houseHoldLabel" | translate }}:
              </span>
              {{ "houseHoldDesc" | translate }}</label
            >
            <br />
            <label class="certify-description">
              {{ "label6" | translate }}</label
            >
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="required">{{ "numHouse" | translate }}</label>
                  <div class="input-group with-focus">
                    <input
                      autocomplete="nope"
                      (keypress)="genericFunctions.numberOnly($event)"
                      type="number"
                      class="form-control"
                      formControlName="numberInHousehold"
                    />
                  </div>
  
                  <div class="text-danger ml-2 mt-2">
                    {{ errors?.numberInHousehold }}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="required">{{ "grossIncome" | translate }}</label>
                  <div class="input-group with-focus">
                    <input
                      autocomplete="nope"
                      (keypress)="genericFunctions.numberOnly($event)"
                      step="0.01"
                      type="number"
                      class="form-control"
                      formControlName="grossMonthlyIncome"
                    />
                  </div>
  
                  <div class="text-danger ml-2 mt-2">
                    {{ errors?.grossMonthlyIncome }}
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <label class="mr-5 text-info text-bold">{{
              "additionalProgElig" | translate
            }}</label>
            <label class="certify-description">
              {{ "progEligSentence" | translate }}
            </label>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <div
                    class="input-group with-focus additionalProgramEligibility"
                  >
                    <tr>
                      <label
                        formArrayName="additionalProgramEligibility"
                        *ngFor="
                          let item of additionalProgramEligibilityFormArray.controls;
                          let i = index
                        "
                        class="c-checkbox "
                        [ngClass]="i % 2 == 0 ? 'padding-margin-left' : 'padding-margin-right'"
                      >
                        <input
                          type="checkbox"
                          [name]="additionalProgramEligibilityList[i].name"
                          [formControlName]="i"
                        />
                        <span class="fa fa-check"></span>
                        {{ additionalProgramEligibilityList[i].name }}
                      </label>
                    </tr>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <h5 class="mr-5 text-info text-bold">
                  {{ "nonDiscriminationStatement" | translate }}
                </h5>
                <p [innerHTML]="'nonDiscriminationText' | translate"></p>
                <p>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <a
                    [href]="'usdaNonDiscriminationLink' | translate"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {{ "usdaNonDiscriminationLink" | translate }}
                  </a>
                </p>
                <br />
              </div>
              <p class="text-info text-center equalOpportunityProvider">
                {{ "equalOpportunityProvider" | translate }}
              </p>
            </div>
            <!-- <ngx-recaptcha2
              #captchaElem
              [siteKey]="siteKey"
              [useGlobalDomain]="false"
              formControlName="recaptcha"
            >
            </ngx-recaptcha2> -->
            <!-- <div class="clearfix mt-2">
              <div class="">
                <a class="forgot-pin" [routerLink]="'/auth/login'">{{
                  "backLogin" | translate
                }}</a>
              </div>
            </div> -->
            <button
              class="btn btn-block btn-primary mt-2"
              type="submit"
              [disabled]="!signUpForm.valid || !signUpForm.dirty"
              (click)="submit()"
            >
              {{ "signUpBtn" | translate }}
            </button>
            <!-- <button
              class="btn btn-block btn-primary mt-2"
              type="submit"
              [disabled]="true"
              (click)="submit()"
            >
              {{ "signUpBtn" | translate }}
            </button> -->
          </form>
        </div>
        <!-- <div class="card-footer text-center">
          <label> {{ "label7" | translate }} @2009-2020 </label>
        </div> -->
      </div>
    </div>
  </div>
  <ng-template #signUpUpdatesModal let-c="close" let-d="dismiss">
    <div class="modal-header">
      <!-- <h4 class="modal-title">{{ "Additional Information" | translate }}</h4> -->
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="onSignUpUpdatesCancel()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <app-sign-up-updates
        [userData]="signUpForm.value"
        (formSubmit)="onSignUpUpdatesSubmit()"
        (formCancel)="onSignUpUpdatesCancel()"
        (formEdit)="onSignUpUpdatesEdit()"
      ></app-sign-up-updates>
    </div>
  </ng-template>
  